import serverSideTranslationProps from "@lib/util/serverSideTranslationProps";
import type { GetStaticProps } from "next";

export async function getStaticPaths() {
	return {
		paths: [],
		fallback: "blocking",
	};
}

export const getStaticProps: GetStaticProps = async (context) => {
	return serverSideTranslationProps(context);
};

export default function IndexPage() {
	return null;
}
